import { booleanToRadioValue } from "@chq/components";
import { AddCoverageFormProps, Fields } from "../components/add-coverage-form";
import { EditedCoverages } from "../data/policy-management/PolicyManagementCoveragesContext";

export const coveragesFieldMapper = (coverages: EditedCoverages["coverages"]): AddCoverageFormProps => {
  return {
    [Fields.trailerInterchange]: booleanToRadioValue(coverages.trailerInterchangeCoverage!),
    [Fields.trailerCoverageLimit]: coverages.trailerInterchangeCoverageLimit!,
    [Fields.hiredAuto]: booleanToRadioValue(coverages.hiredAutoCovers!),
    [Fields.uninsuredMotorist]: booleanToRadioValue(coverages.umUimCoverage!),
    [Fields.cargoInsurance]: booleanToRadioValue(coverages.cargoInsurance!),
    [Fields.cargoLimit]: coverages.cargoInsuranceLimit ? coverages.cargoInsuranceLimit.toString() : undefined,
    [Fields.cargoDeductible]: coverages.cargoInsuranceDeductible
      ? coverages.cargoInsuranceDeductible.toString()
      : undefined,
    [Fields.reeferBreakdown]: booleanToRadioValue(coverages.cargoInsuranceReeferBreakdown!),
  };
};
