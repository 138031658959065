import { NextButton, PlusCircleIcon as PlusCircle, PreviousButton, YesNoValues } from "@chq/components";
import { CompAndCollisionCoverage, Equipment, EquipmentType } from "@chq/enrollment-api";
import { Button, CircularProgress, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldArray, Formik } from "formik";
import React from "react";
import TagManager from "react-gtm-module";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import GenericPageAlert from "../../../components/generic-page-alert";
import PowerUnitsCard from "../../../components/power-units-card";
import {
  Fields as TrailerPowerUnitFormFields,
  TrailerPowerUnitFormProps,
  useValidationSchema as useTrailerPowerUnitFormValidation,
} from "../../../components/trailer-power-unit-form";
import TrailerPowerUnitForm from "../../../components/trailer-power-unit-form-wrapper";
import { useGetApplication } from "../../../data/enrollment";
import { useAddEquipment, useDeleteEquipment, useEditEquipment } from "../../../data/enrollment/useEquipment";
import { equipmentApiMapper } from "../../../utils/equipment-api-mapper";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.5rem 1rem 2.25rem 1rem",
  },
  pageTitle: {
    paddingBottom: "1.5rem",
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  unitCardContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  trailerTitle: {
    paddingBottom: "0.5rem",
    paddingTop: "1rem",
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pageButtons: {
    paddingTop: "1rem",
    justifyContent: "center",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
}));

const MAX_POWER_UNITS = 20;

const yesOrNo = (b: boolean | null | undefined) => {
  if (b !== undefined && b !== null) {
    return b ? YesNoValues.yes : YesNoValues.no;
  } else {
    return undefined;
  }
};

export type TrailerPowerUnit = Partial<TrailerPowerUnitFormProps> & {
  id?: number;
  equipmentType: EquipmentType;
  isEditing?: boolean;
  isAdding?: boolean;
  unitNumber?: number;
};
const EquipmentPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  const history = useHistory();
  const trailerPowerUnitFormValidation = useTrailerPowerUnitFormValidation();
  const { data: application } = useGetApplication(RouteNames.equipment);
  const { mutate: addEquipment, isLoading: isAddingEquipment, isError: isAddError } = useAddEquipment();
  const { mutate: editEquipment, isLoading: isEditingEquipment, isError: isEditError } = useEditEquipment();
  const { mutate: deleteEquipment, isLoading: isDeletingEquipment, isError: isDeleteError } = useDeleteEquipment();

  const initialPowerUnits = (application?.coveredEquipment || []).filter(
    (equipment) => equipment.equipmentType === EquipmentType.PowerUnit,
  );
  const initialTrailers = (application?.coveredEquipment || []).filter(
    (equipment) => equipment.equipmentType === EquipmentType.Trailer,
  );
  return (
    <>
      {(isAddError || isEditError || isDeleteError) && <GenericPageAlert />}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Formik
        initialValues={{
          powerUnits: initialPowerUnits.length
            ? initialPowerUnits.map((unit, index) => ({
                id: unit.id,
                unitNumber: index + 1,
                isEditing: false,
                isAdding: false,
                equipmentType: EquipmentType.PowerUnit,
                [TrailerPowerUnitFormFields.vehicleNumber]: unit.vehicleNumber || undefined,
                [TrailerPowerUnitFormFields.vin]: unit.vin || undefined,
                [TrailerPowerUnitFormFields.make]: unit.make || undefined,
                [TrailerPowerUnitFormFields.model]: unit.model || undefined,
                [TrailerPowerUnitFormFields.year]: unit.year ? Number(unit.year) : undefined,
                [TrailerPowerUnitFormFields.registrationZip]: unit.registrationZip
                  ? String(unit.registrationZip)
                  : undefined,
                [TrailerPowerUnitFormFields.destination]: unit.destinationZone || undefined,
                [TrailerPowerUnitFormFields.ooIC]: yesOrNo(unit.ownerOperator),
                [TrailerPowerUnitFormFields.whereIsUnitRegistered]: unit.registeredTo || undefined,
                [TrailerPowerUnitFormFields.coverageOption]: unit.compAndCollisionCoverage || undefined,
                [TrailerPowerUnitFormFields.statedValue]: unit.statedValue || undefined,
                [TrailerPowerUnitFormFields.gapCoverage]: yesOrNo(unit.loanLeaseGapCoverage),
              }))
            : [{ unitNumber: 1, isEditing: true, isAdding: true, equipmentType: EquipmentType.PowerUnit }],
          trailers: initialTrailers.map((unit, index) => ({
            id: unit.id,
            unitNumber: index + 1,
            isEditing: false,
            isAdding: false,
            equipmentType: EquipmentType.PowerUnit,
            [TrailerPowerUnitFormFields.vehicleNumber]: unit.vehicleNumber || undefined,
            [TrailerPowerUnitFormFields.vin]: unit.vin || undefined,
            [TrailerPowerUnitFormFields.make]: unit.make || undefined,
            [TrailerPowerUnitFormFields.model]: unit.model || undefined,
            [TrailerPowerUnitFormFields.year]: unit.year ? Number(unit.year) : undefined,
            [TrailerPowerUnitFormFields.registrationZip]: unit.registrationZip
              ? String(unit.registrationZip)
              : undefined,
            [TrailerPowerUnitFormFields.destination]: unit.destinationZone || undefined,
            [TrailerPowerUnitFormFields.ooIC]: yesOrNo(unit.ownerOperator),
            [TrailerPowerUnitFormFields.whereIsUnitRegistered]: unit.registeredTo || undefined,
            [TrailerPowerUnitFormFields.coverageOption]: unit.compAndCollisionCoverage || undefined,
            [TrailerPowerUnitFormFields.statedValue]: unit.statedValue || undefined,
            [TrailerPowerUnitFormFields.gapCoverage]: yesOrNo(unit.loanLeaseGapCoverage),
          })),
        }}
        validateOnMount
        enableReinitialize
        validate={({ powerUnits, trailers }) => {
          const errors: Record<string, string> = {};
          const powerUnitsValid =
            powerUnits.length &&
            powerUnits.every((powerUnit) => {
              const coverageAmount = parseInt((powerUnit["coverage-option"] as string)?.replace(/\D/g, ""));
              let hasCoverage: boolean;
              if (powerUnit["coverage-option"] !== CompAndCollisionCoverage.NoCoverage) {
                powerUnit["stated-value"]! > coverageAmount ? (hasCoverage = true) : (hasCoverage = false);
              } else {
                hasCoverage = true;
              }
              hasCoverage = Boolean(powerUnit["operator-or-contractor"]);
              return !powerUnit.isEditing && trailerPowerUnitFormValidation.isValidSync(powerUnit) && hasCoverage;
            });
          const trailersValid = trailers?.every((trailer) => {
            return !trailer.isEditing && trailerPowerUnitFormValidation.isValidSync(trailer);
          });

          if (!powerUnitsValid) {
            errors.powerUnits = "invalid";
          }

          if (!trailersValid) {
            errors.trailers = "invalid";
          }
          return errors;
        }}
        onSubmit={(values: { powerUnits: TrailerPowerUnit[]; trailers: TrailerPowerUnit[] }) => {
          TagManager.dataLayer({
            dataLayer: {
              event: "step3DriversEvent",
              step3DriversText: "Step 3: Drivers",
            },
          });
          history.push(routes.enrollment.drivers.path);
        }}
      >
        {(formik) => (
          <Grid container direction="column" className={classes.container}>
            <Grid container item direction="column" className={classes.pageTitle}>
              <Grid item>
                <Typography variant="h2">{t("enrollment.equipment-page.power-units-title")}</Typography>
              </Grid>
              <Grid item>
                <Typography component="p" variant="h4">
                  {t("enrollment.equipment-page.subtitle")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} className={classes.unitCardContainer}>
              <FieldArray name={"powerUnits"}>
                {({ push, remove }) => {
                  return (
                    <>
                      {formik.values.powerUnits.map((unit, index) => {
                        return (
                          <Grid item key={unit.unitNumber} xs={12} sm={6} lg={4}>
                            {!unit.isEditing ? (
                              <PowerUnitsCard
                                vehicleNumber={unit[TrailerPowerUnitFormFields.vehicleNumber]!}
                                vin={unit[TrailerPowerUnitFormFields.vin]!}
                                make={unit[TrailerPowerUnitFormFields.make]!}
                                model={unit[TrailerPowerUnitFormFields.model]!}
                                year={unit[TrailerPowerUnitFormFields.year]!.toString()}
                                registrationZip={unit[TrailerPowerUnitFormFields.registrationZip]!.toString()}
                                destination={unit[TrailerPowerUnitFormFields.destination]!}
                                ooIC={unit[TrailerPowerUnitFormFields.ooIC]!}
                                whereIsUnitRegistered={unit[TrailerPowerUnitFormFields.whereIsUnitRegistered]!}
                                coverageOption={unit[TrailerPowerUnitFormFields.coverageOption]!}
                                statedValue={unit[TrailerPowerUnitFormFields.statedValue]!}
                                gapCoverage={unit[TrailerPowerUnitFormFields.gapCoverage]!}
                                onEdit={() => {
                                  const powerUnits = formik.values.powerUnits;
                                  formik.setFieldValue(
                                    "powerUnits",
                                    powerUnits.map((item, i) =>
                                      index === i ? { ...item, isEditing: true, isAdding: false } : item,
                                    ),
                                  );
                                }}
                              />
                            ) : (
                              <TrailerPowerUnitForm
                                variant={EquipmentType.PowerUnit}
                                unitCount={index + 1}
                                vehicleNumber={unit[TrailerPowerUnitFormFields.vehicleNumber]}
                                vin={unit[TrailerPowerUnitFormFields.vin]}
                                make={unit[TrailerPowerUnitFormFields.make]}
                                model={unit[TrailerPowerUnitFormFields.model]}
                                year={unit[TrailerPowerUnitFormFields.year]}
                                registrationZip={unit[TrailerPowerUnitFormFields.registrationZip]}
                                destination={unit[TrailerPowerUnitFormFields.destination]}
                                ooIC={unit[TrailerPowerUnitFormFields.ooIC]}
                                whereIsUnitRegistered={unit[TrailerPowerUnitFormFields.whereIsUnitRegistered]!}
                                coverageOption={unit[TrailerPowerUnitFormFields.coverageOption]}
                                statedValue={unit[TrailerPowerUnitFormFields.statedValue]!}
                                gapCoverage={unit[TrailerPowerUnitFormFields.gapCoverage]}
                                IconButtonProps={{ disabled: formik.values.powerUnits.length === 1 }}
                                onDelete={() => {
                                  if (unit.isAdding === true) {
                                    remove(index);
                                  } else {
                                    remove(index);
                                    deleteEquipment({ id: unit.id });
                                  }
                                }}
                                onSubmit={(values) => {
                                  const powerUnits = formik.values.powerUnits;
                                  const newPowerUnits = powerUnits.map((item, i) =>
                                    index === i ? { ...item, ...values, isEditing: false, isAdding: false } : item,
                                  );
                                  const mappedPowerUnit = equipmentApiMapper(
                                    { ...newPowerUnits[index], equipmentType: EquipmentType.PowerUnit },
                                    application?.id,
                                  );

                                  unit.isAdding
                                    ? addEquipment(
                                        {
                                          applicationId: application?.id,
                                          equipment: mappedPowerUnit,
                                        },
                                        {
                                          onSuccess: (data: Equipment | undefined) => {
                                            if (data!.gvwr != null) {
                                              TagManager.dataLayer({
                                                dataLayer: {
                                                  event: "savePowerUnitEvent",
                                                  savePowerUnitText: "Save Power Unit",
                                                },
                                              });
                                              TagManager.dataLayer({
                                                dataLayer: {
                                                  event: "GVWREvent",
                                                  GVWRText: data!.gvwr,
                                                },
                                              });
                                            }
                                            newPowerUnits[index].id = data?.id; // This is where we will set the power unit id so we can identify which one to delete if needed
                                            formik.setFieldValue("powerUnits", newPowerUnits);
                                          },
                                        },
                                      )
                                    : editEquipment({
                                        equipment: mappedPowerUnit,
                                      });
                                  formik.setFieldValue("powerUnits", newPowerUnits);
                                }}
                              />
                            )}
                          </Grid>
                        );
                      })}
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          disabled={formik.values.powerUnits.length >= MAX_POWER_UNITS}
                          startIcon={<PlusCircle className={classes.addIcon} />}
                          onClick={() => {
                            const powerUnits = formik.values.powerUnits;
                            push({
                              unitNumber: powerUnits[powerUnits.length - 1]
                                ? powerUnits[powerUnits.length - 1].unitNumber! + 1
                                : 1,
                              isEditing: true,
                              isAdding: true,
                              equipmentType: EquipmentType.PowerUnit,
                            });
                          }}
                        >
                          {t("enrollment.equipment-page.add-power-units-button")}
                        </Button>
                        {formik.values.powerUnits.length >= MAX_POWER_UNITS && (
                          <Typography component="p">
                            <Trans i18nKey={"enrollment.equipment-page.number-of-units-error"}>
                              <Typography component="span" variant="body1"></Typography>
                              <Link href={"tel:866-621-4145"}></Link>
                              <Typography component="span" variant="body1"></Typography>
                            </Trans>
                          </Typography>
                        )}
                      </Grid>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
            <Grid item container direction="column" className={classes.trailerTitle}>
              <Grid item>
                <Typography variant="h2">{t("enrollment.equipment-page.trailers-title")}</Typography>
              </Grid>
              <Grid item>
                <Typography component="p" variant="h4">
                  {t("enrollment.equipment-page.subtitle")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2} className={classes.unitCardContainer}>
              <FieldArray name={"trailers"}>
                {({ push, remove }) => {
                  return (
                    <>
                      {formik.values.trailers.map((unit, index) => {
                        return (
                          <Grid item key={unit.unitNumber} xs={12} sm={6} lg={4}>
                            {!unit.isEditing ? (
                              <PowerUnitsCard
                                vehicleNumber={unit[TrailerPowerUnitFormFields.vehicleNumber]!}
                                vin={unit[TrailerPowerUnitFormFields.vin]!}
                                make={unit[TrailerPowerUnitFormFields.make]!}
                                model={unit[TrailerPowerUnitFormFields.model]!}
                                year={unit[TrailerPowerUnitFormFields.year]!.toString()}
                                statedValue={unit[TrailerPowerUnitFormFields.statedValue]!}
                                registrationZip={unit[TrailerPowerUnitFormFields.registrationZip]!.toString()}
                                destination={unit[TrailerPowerUnitFormFields.destination]!}
                                ooIC={unit[TrailerPowerUnitFormFields.ooIC]!}
                                whereIsUnitRegistered={unit[TrailerPowerUnitFormFields.whereIsUnitRegistered]!}
                                coverageOption={unit[TrailerPowerUnitFormFields.coverageOption]!}
                                gapCoverage={unit[TrailerPowerUnitFormFields.gapCoverage]!}
                                onEdit={() => {
                                  const trailers = formik.values.trailers;
                                  formik.setFieldValue(
                                    "trailers",
                                    trailers.map((item, i) =>
                                      index === i ? { ...item, isEditing: true, isAdding: false } : item,
                                    ),
                                  );
                                }}
                              />
                            ) : (
                              <TrailerPowerUnitForm
                                variant={EquipmentType.Trailer}
                                unitCount={index + 1}
                                vehicleNumber={unit[TrailerPowerUnitFormFields.vehicleNumber]}
                                vin={unit[TrailerPowerUnitFormFields.vin]}
                                make={unit[TrailerPowerUnitFormFields.make]}
                                model={unit[TrailerPowerUnitFormFields.model]}
                                year={unit[TrailerPowerUnitFormFields.year]}
                                registrationZip={unit[TrailerPowerUnitFormFields.registrationZip]}
                                destination={unit[TrailerPowerUnitFormFields.destination]}
                                ooIC={unit[TrailerPowerUnitFormFields.ooIC]}
                                whereIsUnitRegistered={unit[TrailerPowerUnitFormFields.whereIsUnitRegistered]!}
                                coverageOption={unit[TrailerPowerUnitFormFields.coverageOption]}
                                statedValue={unit[TrailerPowerUnitFormFields.statedValue]!}
                                gapCoverage={unit[TrailerPowerUnitFormFields.gapCoverage]}
                                onDelete={() => {
                                  if (unit.isAdding === true) {
                                    remove(index);
                                  } else {
                                    remove(index);
                                    deleteEquipment({ id: unit.id });
                                  }
                                }}
                                onSubmit={(values, _formik) => {
                                  const trailers = formik.values.trailers;
                                  const newTrailers = trailers.map((item, i) =>
                                    index === i ? { ...item, ...values, isEditing: false, isAdding: false } : item,
                                  );
                                  const mappedTrailer = equipmentApiMapper(
                                    { ...newTrailers[index], equipmentType: EquipmentType.Trailer },
                                    application?.id,
                                  );

                                  unit.isAdding
                                    ? addEquipment(
                                        {
                                          applicationId: application?.id,
                                          equipment: mappedTrailer,
                                        },
                                        {
                                          onSuccess: (data: Equipment | undefined) => {
                                            TagManager.dataLayer({
                                              dataLayer: {
                                                event: "saveTrailerEvent",
                                                saveTrailerText: "Save Trailer",
                                              },
                                            });
                                            newTrailers[index].id = data?.id; // This is where we will set the trailer id so we can identify which one to delete if needed
                                            formik.setFieldValue("trailers", newTrailers);
                                          },
                                        },
                                      )
                                    : editEquipment({
                                        equipment: mappedTrailer,
                                      });
                                  formik.setFieldValue("trailers", newTrailers);
                                }}
                              />
                            )}
                          </Grid>
                        );
                      })}
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<PlusCircle className={classes.addIcon} />}
                          onClick={() => {
                            const trailers = formik.values.trailers;
                            push({
                              unitNumber: trailers[trailers.length - 1]
                                ? trailers[trailers.length - 1].unitNumber! + 1
                                : 1,
                              isEditing: true,
                              isAdding: true,
                              equipmentType: EquipmentType.Trailer,
                            });
                          }}
                        >
                          {t("enrollment.equipment-page.add-trailers-button")}
                        </Button>
                      </Grid>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
            <Grid container item direction="row" className={classes.pageButtons} spacing={2}>
              <Grid item xs={3} sm={1}>
                <PreviousButton
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.previousButton}
                  onClick={() => history.push(routes.enrollment.basicInfo.path)}
                  aria-label={t("enrollment.equipment-page.previous-button-label")}
                ></PreviousButton>
              </Grid>
              <Grid item xs={9} sm={3}>
                <NextButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!formik.isValid || isAddingEquipment || isEditingEquipment || isDeletingEquipment}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {isAddingEquipment || isEditingEquipment || isDeletingEquipment ? (
                    <CircularProgress
                      color="inherit"
                      size="2rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  ) : (
                    t("enrollment.equipment-page.next-button")
                  )}
                </NextButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default EquipmentPage;
