import { Driver, State } from "@chq/enrollment-api";
import { Fields } from "../components/add-driver-form";
import { Driver as DriverPageType } from "../ui/enrollment/pages/drivers-page";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const driverMapper = (driver: DriverPageType, appId?: number): Driver => {
  return {
    id: driver.id,
    firstName: driver[Fields.firstName],
    lastName: driver[Fields.lastName],
    dateOfBirth: driver[Fields.dateOfBirth],
    cdlNumber: driver[Fields.cdlNumber],
    cdlState: driver[Fields.cdlState] as State,
    applicationId: appId,
  };
};
