import { AppBar, AppBarProps, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { HamburgerIcon, XIcon as CloseIcon } from "./icons";
import AonLogo from "./icons/aon-logo";
import ChqLogo from "./icons/chq-logo";
import HeaderBar from "./icons/header-bar";

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    textAlign: "center",
    flex: 1,
  },
  lastMile: {
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
    padding: "0.5rem 0rem",
    "&:empty": {
      display: "none",
    },
  },
  root: {
    padding: "0.5rem 0rem",
    "&:empty": {
      display: "none",
    },
  },
  logo: {
    width: "auto",
    height: "100%",
    maxHeight: "56px",
    maxWidth: "100%",
  },
  chqLogo: {
    padding: "0.5rem 0.75rem",
  },
  middleBar: {
    height: "56px",
  },
  profileSection: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: "5%",
      top: "40%",
    },
  },
  padding: {
    padding: "0.5rem 0rem",
    "&:empty": {
      display: "none",
    },
  },
  childrenContainer: {
    position: "relative",
  },
  hamburgerMenu: {
    position: "absolute",
    left: 0,
    marginLeft: "1rem",
    bottom: 0,
    "& button": {
      padding: "0.5rem",
    },
  },
  onlyHamburgerMenu: {
    left: 0,
    top: "0.5rem",
    position: "absolute",
    marginLeft: "1rem",
  },
}));

export enum LogoType {
  CHQ = 0,
  AON,
}

export type Props = AppBarProps & {
  logo?: LogoType;
  location?: string;
  profileName?: string;
  aonTitleAccess?: string;
  aonAriaLabel?: string;
  chqTitleAccess?: string;
  chqAriaLabel?: string;
  headerBarTitleAccess?: string;
  headerBarAriaLabel?: string;
  lastMile?: boolean;
  onDrawerToggle?: () => void;
  drawerOpen?: boolean;
  menuNotVisibleStatus?: { [x: string]: boolean };
};

const Header: React.FC<Props> = ({
  menuNotVisibleStatus = { url: true },
  profileName,
  aonTitleAccess,
  aonAriaLabel,
  chqTitleAccess,
  chqAriaLabel,
  headerBarAriaLabel,
  headerBarTitleAccess,
  lastMile,
  children,
  onDrawerToggle,
  drawerOpen,
  logo = LogoType.CHQ,
  location,
  ...AppBarProps
}) => {
  const classes = useStyles();

  return (
    <>
      <AppBar
        color="inherit"
        position="sticky"
        elevation={0}
        {...AppBarProps}
        className={classNames(AppBarProps.className, lastMile ? classes.lastMile : classes.root)}
      >
        <Grid container justify="center" direction="column">
          <Grid container item justify="center">
            {children === undefined && onDrawerToggle && drawerOpen !== undefined && (
              <Grid container item direction="row" xs={12} justify="center" className={classes.childrenContainer}>
                <Grid item className={classes.onlyHamburgerMenu}>
                  <IconButton onClick={onDrawerToggle}>{drawerOpen ? <CloseIcon /> : <HamburgerIcon />}</IconButton>
                </Grid>
              </Grid>
            )}
            <Grid item container md={4}>
              <Grid item className={classes.logoContainer}>
                <ChqLogo
                  titleAccess={chqTitleAccess}
                  aria-label={chqAriaLabel}
                  className={classNames(classes.logo, classes.chqLogo)}
                />
              </Grid>
              {logo === LogoType.AON && (
                <>
                  <Grid item>
                    <HeaderBar
                      titleAccess={headerBarTitleAccess}
                      aria-label={headerBarAriaLabel}
                      className={classes.middleBar}
                    />
                  </Grid>
                  <Grid item className={classes.logoContainer}>
                    <AonLogo titleAccess={aonTitleAccess} aria-label={aonAriaLabel} className={classes.logo} />
                  </Grid>
                </>
              )}
            </Grid>
            {profileName && (
              <div className={classes.profileSection}>
                <Typography>Hello, {profileName}</Typography>
              </div>
            )}
          </Grid>
          {children && (
            <Grid container item direction="row" xs={12} justify="center" className={classes.childrenContainer}>
              {location && menuNotVisibleStatus![location] != true && onDrawerToggle && drawerOpen !== undefined && (
                <Grid item className={classes.hamburgerMenu}>
                  <IconButton onClick={onDrawerToggle}>{drawerOpen ? <CloseIcon /> : <HamburgerIcon />}</IconButton>
                </Grid>
              )}
              <Grid item className={classes.padding} id="header-child">
                {children}
              </Grid>
            </Grid>
          )}
        </Grid>
      </AppBar>
    </>
  );
};

export default Header;
