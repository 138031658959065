import {
  AlertType,
  Checkbox,
  DatePicker,
  NumericInput,
  PageAlert,
  Select,
  TextInput,
  TooltipHelp,
} from "@chq/components";
import {
  LegalEntity,
  NotificationPreference,
  OperatingAuthorityStatus,
  PrimaryOperationType,
  ReferralMethod,
} from "@chq/enrollment-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import startOfToday from "date-fns/startOfToday";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useLegalEntityTypes } from "../data/useLegalEntityTypes";
import { useOperatingAuthorityStatues } from "../data/useOperatingAuthorityStatuses";
import { useOperationTypes } from "../data/useOperationTypes";
import { usePreferNotifiedTypes } from "../data/usePreferNotifiedTypes";
import { useReferralMethodTypes } from "../data/useReferralMethodTypes";
import { State, useStates } from "../data/useStates";
import { getFutureDate } from "../utils/get-future-date";

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    marginTop: "1.25rem",
    marginBottom: "0.75rem",
    backgroundColor: "white",
    padding: "0.8rem",
    borderRadius: "0.25rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  input: {
    "& .MuiOutlinedInput-root": {
      [theme.breakpoints.down("xs")]: {
        marginTop: "1rem",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "2rem",
      },
    },
  },
  tooltipText: {
    color: theme.palette.common.white,
  },
  addressErrorMessage: {
    width: "100%",
    marginBottom: "0.5rem",
  },
}));

export enum Fields {
  firstName = "first-name",
  lastName = "last-name",
  companyName = "company-name",
  yearFounded = "year-founded",
  effectiveDate = "effective-date",
  operatingAuthorityStatus = "operating-authority-status",
  operationType = "operation-type",
  legalEntity = "legal-entity",
  phoneNumber = "phone-number",
  addressLineOne = "address-line-one",
  addressLineTwo = "address-line-two",
  city = "city",
  state = "state",
  zip = "zip",
  garagingSame = "garaging-same",
  garagingAddressLineOne = "garaging-address-line-one",
  garagingAddressLineTwo = "garaging-address-line-two",
  garagingCity = "garaging-city",
  garagingState = "garaging-state",
  garagingZip = "garaging-zip",
  addressError = "address-error",
  garagingAddressError = "garaging-address-error",
  previousInsurer = "previous-insurer",
  preferNotified = "prefer-notified",
  referralMethod = "referral-method",
  otherTextField = "other-text-field",
}

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const today = startOfToday();
  const minYearFounded = 1800;
  const maxYearFounded = today.getFullYear();

  return yup.object({
    [Fields.firstName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.firstName}`) })),
    [Fields.lastName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.lastName}`) })),
    [Fields.companyName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.companyName}`) })),
    [Fields.yearFounded]: yup
      .number()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.yearFounded}`) }))
      .min(
        minYearFounded,
        t(`errors.min`, { field: t(`enrollment.basic-info.fields.${Fields.yearFounded}`), min: minYearFounded }),
      )
      .max(
        maxYearFounded,
        t(`errors.max`, { field: t(`enrollment.basic-info.fields.${Fields.yearFounded}`), max: maxYearFounded }),
      ),
    [Fields.effectiveDate]: yup
      .date()
      .nullable()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.effectiveDate}`) }))
      .typeError(t("errors.date", { field: t(`enrollment.basic-info.fields.${Fields.effectiveDate}`) }))
      .min(today, t("enrollment.basic-info.errors.min-effective-date")),
    [Fields.operatingAuthorityStatus]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.operatingAuthorityStatus}`) })),
    [Fields.operationType]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.operationType}`) })),
    [Fields.legalEntity]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.legalEntity}`) })),
    [Fields.phoneNumber]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.phoneNumber}`) })),
    [Fields.addressLineOne]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.addressLineOne}`) })),
    [Fields.addressLineTwo]: yup.string(),
    [Fields.city]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.city}`) })),
    [Fields.state]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.state}`) })),
    [Fields.zip]: yup
      .string()
      .test(
        "length",
        t("enrollment.basic-info.errors.zip-length"),
        (val) => val !== undefined && val.toString().length === 5,
      )
      .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.zip}`) })),

    [Fields.garagingAddressLineOne]: yup.string().when(Fields.garagingSame, {
      is: false || "false",
      then: yup
        .string()
        .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.garagingAddressLineOne}`) })),
      otherwise: yup.string(),
    }),
    [Fields.garagingAddressLineTwo]: yup.string(),
    [Fields.garagingCity]: yup.string().when(Fields.garagingSame, {
      is: false || "false",
      then: yup
        .string()
        .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.garagingCity}`) })),
      otherwise: yup.string(),
    }),
    [Fields.garagingState]: yup.string().when(Fields.garagingSame, {
      is: false || "false",
      then: yup
        .string()
        .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.garagingState}`) })),
      otherwise: yup.string(),
    }),
    [Fields.garagingZip]: yup.string().when(Fields.garagingSame, {
      is: false || "false",
      then: yup
        .string()
        .test(
          "length",
          t("enrollment.basic-info.errors.zip-length"),
          (val) => val !== undefined && val.toString().length === 5,
        )
        .required(t(`errors.required`, { field: t(`enrollment.basic-info.fields.${Fields.garagingZip}`) })),
      otherwise: yup.string(),
    }),
    [Fields.addressError]: yup.bool().oneOf([false], t("enrollment.basic-info.errors.address-mismatch")),
    [Fields.garagingAddressError]: yup.bool().oneOf([false], t("enrollment.basic-info.errors.address-mismatch")),
    [Fields.previousInsurer]: yup.string().max(500),
    [Fields.preferNotified]: yup.string(),
    [Fields.referralMethod]: yup.string(),
    [Fields.otherTextField]: yup.string().max(30),
  });
};

export const useFormikConfig = ({
  firstName: initialFirstName = "",
  lastName: initialLastName = "",
  companyName: initialCompanyName = "",
  yearFounded: initialYearFounded = "",
  effectiveDate: initialEffectiveDate,
  operatingAuthorityStatus: initialOperatingAuthorityStatus = "",
  operationType: initialOperationType = "",
  legalEntity: initialLegalEntity = "",
  phoneNumber: initialPhoneNumber = "",
  addressLineOne: initialAddressLineOne = "",
  addressLineTwo: initialAddressLineTwo = "",
  city: initialCity = "",
  state: initialState = "",
  zip: initialZip = "",
  garagingSame: initalGaragingSame = true,
  garagingAddressLineOne: initialGaragingAddressLineOne = "",
  garagingAddressLineTwo: initialGaragingAddressLineTwo = "",
  garagingCity: initialGaragingCity = "",
  garagingState: initialGaragingState = "",
  garagingZip: initialGaragingZip = "",
  previousInsurer = "",
  preferNotified = "",
  referralMethod = "",
  otherTextField = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.firstName]: initialFirstName,
      [Fields.lastName]: initialLastName,
      [Fields.companyName]: initialCompanyName,
      [Fields.yearFounded]: initialYearFounded,
      [Fields.effectiveDate]: initialEffectiveDate,
      [Fields.operatingAuthorityStatus]: initialOperatingAuthorityStatus,
      [Fields.operationType]: initialOperationType,
      [Fields.legalEntity]: initialLegalEntity,
      [Fields.phoneNumber]: initialPhoneNumber,
      [Fields.addressLineOne]: initialAddressLineOne,
      [Fields.addressLineTwo]: initialAddressLineTwo,
      [Fields.city]: initialCity,
      [Fields.state]: initialState,
      [Fields.zip]: initialZip,
      [Fields.garagingSame]: initalGaragingSame,
      [Fields.garagingAddressLineOne]: initialGaragingAddressLineOne,
      [Fields.garagingAddressLineTwo]: initialGaragingAddressLineTwo,
      [Fields.garagingCity]: initialGaragingCity,
      [Fields.garagingState]: initialGaragingState,
      [Fields.garagingZip]: initialGaragingZip,
      [Fields.previousInsurer]: previousInsurer,
      [Fields.preferNotified]: preferNotified,
      [Fields.referralMethod]: referralMethod,
      [Fields.otherTextField]: otherTextField,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

export type FormProps = {
  [Fields.firstName]: string;
  [Fields.lastName]: string;
  [Fields.companyName]: string;
  [Fields.yearFounded]: string;
  [Fields.effectiveDate]?: Date | null;
  [Fields.operatingAuthorityStatus]?: OperatingAuthorityStatus | "";
  [Fields.operationType]?: PrimaryOperationType | "";
  [Fields.legalEntity]?: LegalEntity | "";
  [Fields.phoneNumber]: string;
  [Fields.addressLineOne]: string;
  [Fields.addressLineTwo]?: string;
  [Fields.city]: string;
  [Fields.state]?: State | "";
  [Fields.zip]: string;
  [Fields.garagingSame]?: boolean;
  [Fields.garagingAddressLineOne]: string;
  [Fields.garagingAddressLineTwo]?: string;
  [Fields.garagingCity]: string;
  [Fields.garagingState]?: State | "";
  [Fields.garagingZip]: string;
  [Fields.addressError]?: boolean;
  [Fields.garagingAddressError]?: boolean;
  [Fields.previousInsurer]?: string;
  [Fields.preferNotified]?: NotificationPreference | "";
  [Fields.referralMethod]?: ReferralMethod | "";
  [Fields.otherTextField]?: string;
};

type Props = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  yearFounded?: string;
  effectiveDate?: Date | null;
  operatingAuthorityStatus?: OperatingAuthorityStatus | "";
  operationType?: PrimaryOperationType | "";
  legalEntity?: LegalEntity | "";
  phoneNumber?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: State | "";
  zip?: string;
  garagingSame?: boolean;
  garagingAddressLineOne?: string;
  garagingAddressLineTwo?: string;
  garagingCity?: string;
  garagingState?: State | "";
  garagingZip?: string;
  addressError?: boolean;
  garagingAddressError?: boolean;
  previousInsurer?: string;
  preferNotified?: NotificationPreference | "";
  referralMethod?: ReferralMethod | "";
  otherTextField?: string;
};

export const BasicInformationForm: React.FC<Props> = ({ addressError, garagingAddressError }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const operatingAuthorityStatuses = useOperatingAuthorityStatues();
  const operationTypes = useOperationTypes();
  const legalEntityTypes = useLegalEntityTypes();
  const formik = useFormikContext<FormProps>();
  const states = useStates();
  const preferNotifedWays = usePreferNotifiedTypes();
  const referralMethodWays = useReferralMethodTypes();
  const maxDate = getFutureDate(startOfToday(), 89);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container className={classes.fields} wrap="wrap" spacing={1}>
        <Grid className="row" container spacing={2} wrap="nowrap">
          <Grid item className="column" xs={6}>
            <TextInput
              fullWidth
              id={Fields.firstName}
              name={Fields.firstName}
              label={t(`enrollment.basic-info.fields.${Fields.firstName}`)}
              value={formik.values[Fields.firstName]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
              helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
            />
          </Grid>
          <Grid item className="column" xs={6}>
            <TextInput
              fullWidth
              id={Fields.lastName}
              name={Fields.lastName}
              label={t(`enrollment.basic-info.fields.${Fields.lastName}`)}
              value={formik.values[Fields.lastName]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
              helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} wrap="nowrap">
          <TextInput
            fullWidth
            id={Fields.companyName}
            name={Fields.companyName}
            label={t(`enrollment.basic-info.fields.${Fields.companyName}`)}
            value={formik.values[Fields.companyName]}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.companyName] && Boolean(formik.errors[Fields.companyName])}
            helperText={formik.touched[Fields.companyName] && formik.errors[Fields.companyName]}
          />
        </Grid>
        <Grid container item xs={12} wrap="nowrap">
          <Select
            fullWidth
            id={Fields.legalEntity}
            name={Fields.legalEntity}
            label={t(`enrollment.basic-info.fields.${Fields.legalEntity}`)}
            items={legalEntityTypes.map((legalEntityType: LegalEntity) => ({
              name: t(`legal-entity-type.${legalEntityType}`),
              value: legalEntityType,
            }))}
            value={formik.values[Fields.legalEntity]}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.legalEntity] && Boolean(formik.errors[Fields.legalEntity])}
            helperText={formik.touched[Fields.legalEntity] && formik.errors[Fields.legalEntity]}
          />
        </Grid>
        <Grid className="row" container spacing={2} wrap="nowrap">
          <Grid item className="column" lg={6} md={7} sm={8} xs={6}>
            <NumericInput
              fullWidth
              id={Fields.yearFounded}
              name={Fields.yearFounded}
              className={classes.input}
              label={t(`enrollment.basic-info.fields.${Fields.yearFounded}`)}
              decimalScale={0}
              format="####"
              value={formik.values[Fields.yearFounded] === "" ? null : Number(formik.values[Fields.yearFounded])}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.yearFounded] && Boolean(formik.errors[Fields.yearFounded])}
              helperText={formik.touched[Fields.yearFounded] && formik.errors[Fields.yearFounded]}
            />
          </Grid>
          <Grid item className="column" lg={6} md={8} sm={11} xs={7}>
            <DatePicker
              fullWidth
              id={Fields.effectiveDate}
              name={Fields.effectiveDate}
              className={classes.input}
              label={t(`enrollment.basic-info.fields.${Fields.effectiveDate}`)}
              KeyboardButtonProps={{
                "aria-label": t("enrollment.basic-info.effective-date-button"),
              }}
              value={formik.values[Fields.effectiveDate] || null}
              maxDate={maxDate}
              required
              disablePast
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(date: any) => {
                formik.setFieldValue(Fields.effectiveDate, date);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.effectiveDate] && Boolean(formik.errors[Fields.effectiveDate])}
              helperText={formik.touched[Fields.effectiveDate] && formik.errors[Fields.effectiveDate]}
            />
          </Grid>
        </Grid>
        <Grid item lg={8} sm={8} xs={7}>
          <NumericInput
            required
            fullWidth
            isNumericString
            format="(###) ###-####"
            id={Fields.phoneNumber}
            name={Fields.phoneNumber}
            label={t(`enrollment.basic-info.fields.${Fields.phoneNumber}`)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.phoneNumber]}
            error={formik.touched[Fields.phoneNumber] && Boolean(formik.errors[Fields.phoneNumber])}
            helperText={formik.touched[Fields.phoneNumber] && formik.errors[Fields.phoneNumber]}
          />
        </Grid>
        {/* **************************** ADDRESS **************************** */}
        {addressError && (
          <PageAlert
            className={classes.addressErrorMessage}
            alertType={AlertType.error}
            message={t("enrollment.basic-info.errors.address-mismatch")}
          />
        )}

        <Grid item xs={12}>
          <TextInput
            required
            fullWidth
            id={Fields.addressLineOne}
            name={Fields.addressLineOne}
            label={t(`enrollment.basic-info.fields.${Fields.addressLineOne}`)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.addressLineOne]}
            error={formik.touched[Fields.addressLineOne] && Boolean(formik.errors[Fields.addressLineOne])}
            helperText={formik.touched[Fields.addressLineOne] && formik.errors[Fields.addressLineOne]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            id={Fields.addressLineTwo}
            name={Fields.addressLineTwo}
            label={t(`enrollment.basic-info.fields.${Fields.addressLineTwo}`)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.addressLineTwo]}
            error={formik.touched[Fields.addressLineTwo] && Boolean(formik.errors[Fields.addressLineTwo])}
            helperText={formik.touched[Fields.addressLineTwo] && formik.errors[Fields.addressLineTwo]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            required
            fullWidth
            id={Fields.city}
            name={Fields.city}
            label={t(`enrollment.basic-info.fields.${Fields.city}`)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.city]}
            error={formik.touched[Fields.city] && Boolean(formik.errors[Fields.city])}
            helperText={formik.touched[Fields.city] && formik.errors[Fields.city]}
          />
        </Grid>
        <Grid container className="row" spacing={2} wrap="nowrap">
          <Grid className="column" item xs={6}>
            <Select
              fullWidth
              id={Fields.state}
              name={Fields.state}
              label={t(`enrollment.basic-info.fields.${Fields.state}`)}
              items={states.map((state: State) => ({
                name: state.abv,
                value: state.abv,
              }))}
              value={formik.values[Fields.state]}
              required
              onChange={(e) => {
                if (formik.values[Fields.garagingState] !== e.target.value) {
                  formik.setFieldValue(Fields.garagingSame, false);
                } else {
                  formik.setFieldValue(Fields.garagingSame, true);
                  formik.setFieldValue(Fields.state, formik.values[Fields.garagingState]);
                  formik.setFieldValue(Fields.garagingAddressLineOne, "");
                  formik.setFieldValue(Fields.garagingAddressLineTwo, "");
                  formik.setFieldValue(Fields.garagingCity, "");
                  formik.setFieldValue(Fields.garagingZip, "");
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.state] && Boolean(formik.errors[Fields.state])}
              helperText={formik.touched[Fields.state] && formik.errors[Fields.state]}
            />
          </Grid>
          <Grid className="column" item xs={6}>
            <NumericInput
              required
              fullWidth
              isNumericString
              format="#####"
              id={Fields.zip}
              name={Fields.zip}
              label={t(`enrollment.basic-info.fields.${Fields.zip}`)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[Fields.zip]}
              error={formik.touched[Fields.zip] && Boolean(formik.errors[Fields.zip])}
              helperText={formik.touched[Fields.zip] && formik.errors[Fields.zip]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            label={t(`enrollment.basic-info.fields.${Fields.garagingSame}`)}
            id={Fields.garagingSame}
            name={Fields.garagingSame}
            value={formik.values[Fields.garagingSame]}
            checked={formik.values[Fields.garagingSame]}
            onChange={(e) => {
              formik.handleChange(e);
              if (Fields.garagingSame) {
                formik.setFieldValue(Fields.state, formik.values[Fields.garagingState]);
                formik.setFieldValue(Fields.garagingAddressLineOne, "");
                formik.setFieldValue(Fields.garagingAddressLineTwo, "");
                formik.setFieldValue(Fields.garagingCity, "");
                formik.setFieldValue(Fields.garagingZip, "");
              }
            }}
          />
        </Grid>
        {/* **************************** GARAGING ADDRESS **************************** */}
        {!formik.values[Fields.garagingSame] && (
          <Grid item xs={12}>
            {garagingAddressError && (
              <PageAlert
                className={classes.addressErrorMessage}
                alertType={AlertType.error}
                message={t("enrollment.basic-info.errors.address-mismatch")}
              />
            )}

            <Grid item xs={12}>
              <TextInput
                required
                fullWidth
                id={Fields.garagingAddressLineOne}
                name={Fields.garagingAddressLineOne}
                label={t(`enrollment.basic-info.fields.${Fields.garagingAddressLineOne}`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.garagingAddressLineOne]}
                error={
                  formik.touched[Fields.garagingAddressLineOne] && Boolean(formik.errors[Fields.garagingAddressLineOne])
                }
                helperText={
                  formik.touched[Fields.garagingAddressLineOne] && formik.errors[Fields.garagingAddressLineOne]
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                id={Fields.garagingAddressLineTwo}
                name={Fields.garagingAddressLineTwo}
                label={t(`enrollment.basic-info.fields.${Fields.garagingAddressLineTwo}`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.garagingAddressLineTwo]}
                error={
                  formik.touched[Fields.garagingAddressLineTwo] && Boolean(formik.errors[Fields.garagingAddressLineTwo])
                }
                helperText={
                  formik.touched[Fields.garagingAddressLineTwo] && formik.errors[Fields.garagingAddressLineTwo]
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                required
                fullWidth
                id={Fields.garagingCity}
                name={Fields.garagingCity}
                label={t(`enrollment.basic-info.fields.${Fields.garagingCity}`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.garagingCity]}
                error={formik.touched[Fields.garagingCity] && Boolean(formik.errors[Fields.garagingCity])}
                helperText={formik.touched[Fields.garagingCity] && formik.errors[Fields.garagingCity]}
              />
            </Grid>
            <Grid container className="row" spacing={2} wrap="nowrap">
              <Grid className="column" item xs={6}>
                <Select
                  disabled={true}
                  fullWidth
                  id={Fields.garagingState}
                  name={Fields.garagingState}
                  label={t(`enrollment.basic-info.fields.${Fields.garagingState}`)}
                  items={states.map((state: State) => ({
                    name: state.abv,
                    value: state.abv,
                  }))}
                  value={formik.values[Fields.garagingState]}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.garagingState] && Boolean(formik.errors[Fields.garagingState])}
                  helperText={formik.touched[Fields.garagingState] && formik.errors[Fields.garagingState]}
                  labelAdornment={
                    <TooltipHelp
                      title={
                        <>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="h4" className={classes.tooltipText}>
                                <strong>{t(`enrollment.basic-info.fields.garaging-state-tooltip-text`)}</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      }
                      placement="left-end"
                    />
                  }
                />
              </Grid>
              <Grid className="column" item xs={6}>
                <NumericInput
                  required
                  fullWidth
                  isNumericString
                  format="#####"
                  id={Fields.garagingZip}
                  name={Fields.garagingZip}
                  label={t(`enrollment.basic-info.fields.${Fields.garagingZip}`)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[Fields.garagingZip]}
                  error={formik.touched[Fields.garagingZip] && Boolean(formik.errors[Fields.garagingZip])}
                  helperText={formik.touched[Fields.garagingZip] && formik.errors[Fields.garagingZip]}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12} wrap="nowrap">
          <Select
            fullWidth
            id={Fields.operatingAuthorityStatus}
            name={Fields.operatingAuthorityStatus}
            label={t(`enrollment.basic-info.fields.${Fields.operatingAuthorityStatus}`)}
            items={operatingAuthorityStatuses.map((status: OperatingAuthorityStatus) => ({
              name: t(`operating-authority-status.${status}`),
              value: status,
            }))}
            value={formik.values[Fields.operatingAuthorityStatus]}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.operatingAuthorityStatus] && Boolean(formik.errors[Fields.operatingAuthorityStatus])
            }
            helperText={
              formik.touched[Fields.operatingAuthorityStatus] && formik.errors[Fields.operatingAuthorityStatus]
            }
          />
        </Grid>
        <Grid container item xs={12} wrap="nowrap">
          <Select
            fullWidth
            id={Fields.operationType}
            name={Fields.operationType}
            label={t(`enrollment.basic-info.fields.${Fields.operationType}`)}
            items={operationTypes.map((operationType: PrimaryOperationType) => ({
              name: t(`operation-type.${operationType}`),
              value: operationType,
            }))}
            value={formik.values[Fields.operationType]}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.operationType] && Boolean(formik.errors[Fields.operationType])}
            helperText={formik.touched[Fields.operationType] && formik.errors[Fields.operationType]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            label={t(`enrollment.basic-info.fields.${Fields.previousInsurer}.label`)}
            id={Fields.previousInsurer}
            name={Fields.previousInsurer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.previousInsurer]}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            label={t(`enrollment.basic-info.fields.${Fields.preferNotified}.label`)}
            id={Fields.preferNotified}
            name={Fields.preferNotified}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.preferNotified]}
            items={preferNotifedWays.map((status: NotificationPreference) => ({
              name: t(`prefer-notified.${status}`),
              value: status,
            }))}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            label={t(`enrollment.basic-info.fields.${Fields.referralMethod}.label`)}
            id={Fields.referralMethod}
            name={Fields.referralMethod}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.referralMethod]}
            items={referralMethodWays.map((status: ReferralMethod) => ({
              name: t(`referral-method.${status}`),
              value: status,
            }))}
          />
        </Grid>
        {formik.values[Fields.referralMethod] === ReferralMethod.Other && (
          <Grid item container xs={12} wrap="nowrap">
            <TextInput
              fullWidth
              id={Fields.otherTextField}
              name={Fields.otherTextField}
              label={t(`enrollment.basic-info.fields.other-text-field-label`)}
              value={formik.values[Fields.otherTextField]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.otherTextField] && Boolean(formik.errors[Fields.otherTextField])}
              helperText={formik.touched[Fields.otherTextField] && formik.errors[Fields.otherTextField]}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default BasicInformationForm;
