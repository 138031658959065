import { EmailInput, NumberPicker, NumericInput, Select, TooltipHelp } from "@chq/components";
import { RadiusOfOperation, State } from "@chq/enrollment-api";
import { Card, CardContent, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useRadiusOptions } from "../data/useRadiusOptions";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  dotInput: {
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      "& div": {
        // This captures the tooltip button
        "& div": {
          left: 0,
        },
      },
    },
  },
  button: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: "2rem",
    },
  },
  numberPicker: {
    whiteSpace: "nowrap",
  },
  emailInput: {
    paddingRight: "2rem",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  infoNotice: {
    minHeight: "70px",
    backgroundColor: theme.palette.secondary.light,
    display: "flex",
    justifyContent: "center",
    padding: ".75rem",
    borderRadius: ".25rem",
    marginTop: "0.25rem",
  },
}));

export enum Fields {
  email = "email",
  dotNum = "dot-num",
  vehicleLocation = "vehicle-location",
  radiusOperation = "radius-operation",
  powerUnitCount = "power-unit-count",
}

export type QualificationFormProps = {
  [Fields.email]: string;
  [Fields.dotNum]: string;
  [Fields.vehicleLocation]: State | "";
  [Fields.radiusOperation]: RadiusOfOperation | "";
  [Fields.powerUnitCount]: number;
};

type Props = {
  email?: string;
  emailDisabled?: boolean;
  dotNum?: string;
  vehicleLocation?: State | "";
  radiusOperation?: RadiusOfOperation | "";
  powerUnitCount?: number;
  setPowerUnitError?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: FormikConfig<QualificationFormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return Yup.object({
    [Fields.dotNum]: Yup.string().test(
      "length",
      t("errors.between-digits", { field: t(`qualification-form.dot-num.label`), min: "5", max: "8" }),
      (val) => (val ? val.toString().length >= 5 && val.toString().length <= 8 : true),
    ),
    [Fields.vehicleLocation]: Yup.string().required(
      t(`errors.required`, { field: t(`qualification-form.${Fields.vehicleLocation}.help-text-error`) }),
    ),
    [Fields.radiusOperation]: Yup.string().required(
      t(`errors.required`, { field: t(`qualification-form.${Fields.radiusOperation}.help-text-error`) }),
    ),
    [Fields.powerUnitCount]: Yup.number()
      .required(t(`errors.required`, { field: t(`qualification-form.${Fields.powerUnitCount}.help-text-error`) }))
      .typeError(t(`errors.required`, { field: t(`qualification-form.${Fields.powerUnitCount}.help-text-error`) })),
    [Fields.email]: Yup.string()
      .email(t("errors.invalid-email"))
      .required(t("errors.required", { field: t("email-field.label") })),
  });
};

export const useFormikConfig = ({
  email = "",
  dotNum = "",
  vehicleLocation = "",
  radiusOperation = "",
  powerUnitCount = 1,
}: Props = {}): Omit<FormikConfig<QualificationFormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: email,
      [Fields.dotNum]: dotNum,
      [Fields.vehicleLocation]: vehicleLocation,
      [Fields.radiusOperation]: radiusOperation,
      [Fields.powerUnitCount]: powerUnitCount,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const QualificationForm: React.FC<Props> = ({ emailDisabled }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const states = useStates();
  const radiusOptions = useRadiusOptions();
  const formik = useFormikContext<QualificationFormProps>();

  return (
    <Card>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <NumberPicker
            required
            min={1}
            id="powerUnitCount"
            name={Fields.powerUnitCount}
            color="primary"
            className={classes.numberPicker}
            label={t("qualification-form.power-unit-count.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.powerUnitCount]}
            error={formik.touched[Fields.powerUnitCount] && Boolean(formik.errors[Fields.powerUnitCount])}
            helperText={formik.touched[Fields.powerUnitCount] && formik.errors[Fields.powerUnitCount]}
          />
          <Select
            required
            fullWidth
            id="vehicleLocation"
            name={Fields.vehicleLocation}
            label={t("qualification-form.vehicle-location.label")}
            labelAdornment={
              <TooltipHelp title={t("qualification-form.vehicle-location.help-text").toString()} placement="left-end" />
            }
            items={states.map((state) => ({
              name: state.name,
              value: state.abv,
            }))}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.vehicleLocation]}
            error={formik.touched[Fields.vehicleLocation] && Boolean(formik.errors[Fields.vehicleLocation])}
            helperText={formik.touched[Fields.vehicleLocation] && formik.errors[Fields.vehicleLocation]}
          />
          <Select
            required
            fullWidth
            id="radiusOperation"
            name={Fields.radiusOperation}
            label={t("qualification-form.radius-operation.label")}
            items={radiusOptions.map((radiusOption: RadiusOfOperation) => ({
              name: t(`qualification-form.radius-operation.radius-options.${radiusOption}`),
              value: radiusOption,
            }))}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.radiusOperation]}
            error={formik.touched[Fields.radiusOperation] && Boolean(formik.errors[Fields.radiusOperation])}
            helperText={formik.touched[Fields.radiusOperation] && formik.errors[Fields.radiusOperation]}
          />
          <NumericInput
            fullWidth
            format="########"
            id="dotNum"
            name={Fields.dotNum}
            label={t("qualification-form.dot-num.label")}
            labelEmbellishment={t("qualification-form.dot-num.sub-label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.dotNum] && Boolean(formik.errors[Fields.dotNum])}
            value={formik.values[Fields.dotNum]}
            helperText={formik.touched[Fields.dotNum] && formik.errors[Fields.dotNum]}
          />
          <EmailInput
            required
            fullWidth
            id="email"
            name={Fields.email}
            label={t("email-field.label")}
            labelEmbellishment={t("email-field.sub-label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.email]}
            error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
            helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
            disabled={emailDisabled}
          />
        </form>
      </CardContent>
    </Card>
  );
};

export default QualificationForm;
