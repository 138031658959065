/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Address,
  AddressFromJSON,
  AddressFromJSONTyped,
  AddressToJSON,
  ELDProvider,
  ELDProviderFromJSON,
  ELDProviderFromJSONTyped,
  ELDProviderToJSON,
  LegalEntity,
  LegalEntityFromJSON,
  LegalEntityFromJSONTyped,
  LegalEntityToJSON,
  OperatingAuthorityStatus,
  OperatingAuthorityStatusFromJSON,
  OperatingAuthorityStatusFromJSONTyped,
  OperatingAuthorityStatusToJSON,
  Owner,
  OwnerFromJSON,
  OwnerFromJSONTyped,
  OwnerToJSON,
  PrimaryOperationType,
  PrimaryOperationTypeFromJSON,
  PrimaryOperationTypeFromJSONTyped,
  PrimaryOperationTypeToJSON,
  RadiusOfOperation,
  RadiusOfOperationFromJSON,
  RadiusOfOperationFromJSONTyped,
  RadiusOfOperationToJSON,
} from "./";

/**
 *
 * @export
 * @interface Business
 */
export interface Business {
  /**
   *
   * @type {number}
   * @memberof Business
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  businessName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  contactPhone?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  dotNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof Business
   */
  foundingYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Business
   */
  powerUnitCount?: number;
  /**
   *
   * @type {OperatingAuthorityStatus}
   * @memberof Business
   */
  operatingAuthorityStatus?: OperatingAuthorityStatus;
  /**
   *
   * @type {PrimaryOperationType}
   * @memberof Business
   */
  operationType?: PrimaryOperationType;
  /**
   *
   * @type {RadiusOfOperation}
   * @memberof Business
   */
  radiusOfOperation?: RadiusOfOperation;
  /**
   *
   * @type {LegalEntity}
   * @memberof Business
   */
  legalEntity?: LegalEntity;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  einNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  mcNumber?: string | null;
  /**
   *
   * @type {ELDProvider}
   * @memberof Business
   */
  eldProvider?: ELDProvider;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  eldAdminName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Business
   */
  fleetAccountNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Business
   */
  garagingAddressSameAsBusiness?: boolean;
  /**
   *
   * @type {Address}
   * @memberof Business
   */
  address?: Address;
  /**
   *
   * @type {Address}
   * @memberof Business
   */
  garagingAddress?: Address;
  /**
   *
   * @type {Owner}
   * @memberof Business
   */
  owner?: Owner;
  /**
   *
   * @type {number}
   * @memberof Business
   */
  addressId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Business
   */
  garagingAddressId?: number | null;
  /**
   *
   * @type {number}
   * @memberof Business
   */
  ownerId?: number | null;
}

export function BusinessFromJSON(json: any): Business {
  return BusinessFromJSONTyped(json, false);
}

export function BusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Business {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    businessName: !exists(json, "businessName") ? undefined : json["businessName"],
    contactFirstName: !exists(json, "contactFirstName") ? undefined : json["contactFirstName"],
    contactLastName: !exists(json, "contactLastName") ? undefined : json["contactLastName"],
    contactEmail: !exists(json, "contactEmail") ? undefined : json["contactEmail"],
    contactPhone: !exists(json, "contactPhone") ? undefined : json["contactPhone"],
    dotNumber: !exists(json, "dotNumber") ? undefined : json["dotNumber"],
    foundingYear: !exists(json, "foundingYear") ? undefined : json["foundingYear"],
    powerUnitCount: !exists(json, "powerUnitCount") ? undefined : json["powerUnitCount"],
    operatingAuthorityStatus: !exists(json, "operatingAuthorityStatus")
      ? undefined
      : OperatingAuthorityStatusFromJSON(json["operatingAuthorityStatus"]),
    operationType: !exists(json, "operationType") ? undefined : PrimaryOperationTypeFromJSON(json["operationType"]),
    radiusOfOperation: !exists(json, "radiusOfOperation")
      ? undefined
      : RadiusOfOperationFromJSON(json["radiusOfOperation"]),
    legalEntity: !exists(json, "legalEntity") ? undefined : LegalEntityFromJSON(json["legalEntity"]),
    einNumber: !exists(json, "einNumber") ? undefined : json["einNumber"],
    mcNumber: !exists(json, "mcNumber") ? undefined : json["mcNumber"],
    eldProvider: !exists(json, "eldProvider") ? undefined : ELDProviderFromJSON(json["eldProvider"]),
    eldAdminName: !exists(json, "eldAdminName") ? undefined : json["eldAdminName"],
    fleetAccountNumber: !exists(json, "fleetAccountNumber") ? undefined : json["fleetAccountNumber"],
    garagingAddressSameAsBusiness: !exists(json, "garagingAddressSameAsBusiness")
      ? undefined
      : json["garagingAddressSameAsBusiness"],
    address: !exists(json, "address") ? undefined : AddressFromJSON(json["address"]),
    garagingAddress: !exists(json, "garagingAddress") ? undefined : AddressFromJSON(json["garagingAddress"]),
    owner: !exists(json, "owner") ? undefined : OwnerFromJSON(json["owner"]),
    addressId: !exists(json, "addressId") ? undefined : json["addressId"],
    garagingAddressId: !exists(json, "garagingAddressId") ? undefined : json["garagingAddressId"],
    ownerId: !exists(json, "ownerId") ? undefined : json["ownerId"],
  };
}

export function BusinessToJSON(value?: Business | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    businessName: value.businessName,
    contactFirstName: value.contactFirstName,
    contactLastName: value.contactLastName,
    contactEmail: value.contactEmail,
    contactPhone: value.contactPhone,
    dotNumber: value.dotNumber,
    foundingYear: value.foundingYear,
    powerUnitCount: value.powerUnitCount,
    operatingAuthorityStatus: OperatingAuthorityStatusToJSON(value.operatingAuthorityStatus),
    operationType: PrimaryOperationTypeToJSON(value.operationType),
    radiusOfOperation: RadiusOfOperationToJSON(value.radiusOfOperation),
    legalEntity: LegalEntityToJSON(value.legalEntity),
    einNumber: value.einNumber,
    mcNumber: value.mcNumber,
    eldProvider: ELDProviderToJSON(value.eldProvider),
    eldAdminName: value.eldAdminName,
    fleetAccountNumber: value.fleetAccountNumber,
    garagingAddressSameAsBusiness: value.garagingAddressSameAsBusiness,
    address: AddressToJSON(value.address),
    garagingAddress: AddressToJSON(value.garagingAddress),
    owner: OwnerToJSON(value.owner),
    addressId: value.addressId,
    garagingAddressId: value.garagingAddressId,
    ownerId: value.ownerId,
  };
}
