import { NextButton } from "@chq/components";
import {
  LegalEntity,
  NotificationPreference,
  OperatingAuthorityStatus,
  PrimaryOperationType,
  ReferralMethod,
  State,
} from "@chq/enrollment-api";
import { CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import BasicInformationForm, {
  Fields,
  FormProps as BasicInfo,
  useFormikConfig,
} from "../../../components/basic-information-form";
import GenericPageAlert from "../../../components/generic-page-alert";
import { useAddressValidation, useEditApplication, useGetApplication } from "../../../data/enrollment";
import { qualifyRedirect } from "../../../utils/qualify-redirect";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.5rem 1rem 2.25rem 1rem",
    alignItems: "center",
  },
  titleItem: {
    margin: "auto",
  },
  button: {
    margin: "auto",
  },
}));

const BasicInfoPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { data: application } = useGetApplication(RouteNames.basicInfo);
  const {
    mutateAsync: checkAddressValid,
    isError: isAddressError,
    isLoading: isCheckingValidation,
  } = useAddressValidation();
  const [addressError, setAddressError] = useState<boolean>(false);
  const [garagingAddressError, setGaragingAddressError] = useState(false);

  const { mutate: editApplication, isLoading: isEditingApplication, isError } = useEditApplication();
  const formikProps = useFormikConfig({
    firstName: application?.business?.contactFirstName || "",
    lastName: application?.business?.contactLastName || "",
    companyName: application?.business?.businessName || "",
    yearFounded: application?.business?.foundingYear ? String(application?.business?.foundingYear) : "",
    effectiveDate: application?.desiredEffectiveDate,
    phoneNumber: application?.business?.contactPhone || "",
    operatingAuthorityStatus: application?.business?.operatingAuthorityStatus || "",
    operationType: application?.business?.operationType || "",
    legalEntity: application?.business?.legalEntity || "",
    addressLineOne: application?.business?.address?.address1 || "",
    addressLineTwo: application?.business?.address?.address2 || "",
    city: application?.business?.address?.city || "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: (application?.business?.address?.state as any) || "",
    zip: application?.business?.address?.zip || "",

    //change these to be their own values once pushed to api
    garagingAddressLineOne: application?.business?.garagingAddress?.address1 || "",
    garagingAddressLineTwo: application?.business?.garagingAddress?.address2 || "",
    garagingCity: application?.business?.garagingAddress?.city || "",
    garagingState: application?.business?.garagingAddressSameAsBusiness
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (application?.business?.address?.state as State) || ""
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (application?.business?.garagingAddress?.state as any) || "",
    garagingZip: application?.business?.garagingAddress?.zip || "",
    garagingSame: application?.business?.garagingAddressSameAsBusiness,
    addressError: addressError || false,
    garagingAddressError: garagingAddressError || false,
    previousInsurer: application?.previousInsurer || "",
    preferNotified: application?.notificationPreference || "",
    referralMethod: application?.referralMethod || "",
    otherTextField: application?.otherReferralMethod || "",
  });

  return (
    <>
      {(isError || isAddressError) && <GenericPageAlert />}
      {!application?.qualified && qualifyRedirect(application?.ineligibleReason || "")}
      <Formik
        {...formikProps}
        onSubmit={async (values: BasicInfo) => {
          if (window.LO && window.LO.visitor) {
            window.LO?.visitor.identify(application?.business?.dotNumber || application?.id?.toString() || "", {
              email: application?.business?.contactEmail,
              id: application?.businessId?.toString(),
              phone: values[Fields.phoneNumber],
            });
          }
          const addressValid = await checkAddressValid(
            {
              address: {
                address1: values[Fields.addressLineOne] || "",
                address2: values[Fields.addressLineTwo] || "",
                city: values[Fields.city] || "",
                state: (values[Fields.state] as State) || undefined,
                zip: values[Fields.zip] || "",
              },
            },
            {
              onSuccess: (response) => {
                if (response) {
                  setAddressError(false);
                } else {
                  setAddressError(true);
                }
              },
            },
          );

          const garagingAddressValid = !values[Fields.garagingSame]
            ? await checkAddressValid(
                {
                  address: {
                    address1: values[Fields.garagingAddressLineOne] || "",
                    address2: values[Fields.garagingAddressLineTwo] || "",
                    city: values[Fields.garagingCity] || "",
                    state: (values[Fields.garagingState] as State) || undefined,
                    zip: values[Fields.garagingZip] || "",
                  },
                },
                {
                  onSuccess: (response) => {
                    if (response) {
                      setGaragingAddressError(false);
                    } else {
                      setGaragingAddressError(true);
                    }
                  },
                  onError: (response) => {
                    setGaragingAddressError(true);
                  },
                },
              )
            : true;

          if (addressValid && garagingAddressValid) {
            TagManager.dataLayer({
              dataLayer: {
                event: "step2EquipmentEvent",
                step2EquipmentText: "Step 2: Equipment",
                legalEntity: values[Fields.legalEntity] as LegalEntity,
                zip: values[Fields.zip],
                operatingAuthorityStatus: values[Fields.operatingAuthorityStatus] as OperatingAuthorityStatus,
                operationType: values[Fields.operationType] as PrimaryOperationType,
                notificationPreference: values[Fields.preferNotified] as NotificationPreference,
                referralMethod: values[Fields.referralMethod] as ReferralMethod,
                yearsInService: new Date().getFullYear() - parseInt(values[Fields.yearFounded]),
              },
            });

            editApplication(
              {
                ...application,
                business: {
                  ...application?.business,
                  id: application?.businessId,
                  contactFirstName: values[Fields.firstName],
                  contactLastName: values[Fields.lastName],
                  contactPhone: values[Fields.phoneNumber],
                  businessName: values[Fields.companyName],
                  foundingYear: parseInt(values[Fields.yearFounded]),
                  operatingAuthorityStatus: values[Fields.operatingAuthorityStatus] as OperatingAuthorityStatus,
                  operationType: values[Fields.operationType] as PrimaryOperationType,
                  legalEntity: values[Fields.legalEntity] as LegalEntity,
                  garagingAddressSameAsBusiness: values[Fields.garagingSame],
                  address: {
                    ...application?.business?.address,
                    address1: values[Fields.addressLineOne],
                    address2: values[Fields.addressLineTwo],
                    city: values[Fields.city],
                    state: values[Fields.state] as State,
                    zip: values[Fields.zip],
                  },
                  garagingAddress:
                    values[Fields.garagingSame] === false
                      ? {
                          ...application?.business?.garagingAddress,
                          address1: values[Fields.garagingAddressLineOne],
                          address2: values[Fields.garagingAddressLineTwo],
                          city: values[Fields.garagingCity],
                          state: values[Fields.garagingState] as State,
                          zip: values[Fields.garagingZip],
                        }
                      : undefined,
                },
                previousInsurer: values[Fields.previousInsurer],
                notificationPreference: values[Fields.preferNotified] as NotificationPreference,
                referralMethod: values[Fields.referralMethod] as ReferralMethod,
                otherReferralMethod: values[Fields.otherTextField] || "",
                desiredEffectiveDate: values[Fields.effectiveDate],
              },
              { onSuccess: () => history.push(routes.enrollment.equipment.path) },
            );
          }
        }}
      >
        {(formik) => (
          <Grid container item direction="column" className={classes.container} xs={12}>
            <Grid container item direction="column" xs={12} sm={8}>
              <Grid item container>
                <Typography variant="h2" className={classes.titleItem}>
                  {t("enrollment.basic-info.title")}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography component="p" variant="h4" className={classes.titleItem}>
                  {t("enrollment.basic-info.description")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={12} sm={6}>
              <Grid item>
                <BasicInformationForm addressError={addressError} garagingAddressError={garagingAddressError} />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.button}>
                <NextButton
                  color="primary"
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  disabled={
                    !formik.isValid || isEditingApplication || isError || isAddressError || isCheckingValidation
                  }
                >
                  {isEditingApplication || isCheckingValidation ? (
                    <CircularProgress
                      color="inherit"
                      size="2rem"
                      aria-label={t("common.circular-progress-aria-label")}
                    />
                  ) : (
                    t("enrollment.basic-info.next-button")
                  )}
                </NextButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default BasicInfoPage;
