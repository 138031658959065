import { Card, CardContent, Grid, Link, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Trans, useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import checkIcon from "../images/buttonCheck.svg";
import moneyIcon from "../images/buttonMoney.svg";
import truckIcon from "../images/buttonTruck.svg";
import emailIcon from "../images/email.svg";
import textBackgroundDesktop from "../images/partner-images/text-background-desktop.png";
import textBackground from "../images/partner-images/text-background.png";
import phoneIcon from "../images/phone.svg";
import truckAnimation from "../lotties/text-lottie.json";
import { routes } from "../ui/routes";

type Props = {
  bgImage: string;
  bgImageDesktop: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: ({ bgImage, bgImageDesktop }: Props) => {
    return {
      [theme.breakpoints.up("xs")]: {
        backgroundSize: "cover",
        background: `url(${bgImage})`,
      },
      backgroundRepeat: "no-repeat",
      [theme.breakpoints.up("sm")]: {
        backgroundSize: "cover",
        backgroundPosition: "right center",
        background: `url(${bgImageDesktop})`,
      },
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    };
  },
  partnerPaper: {
    padding: "0",
    [theme.breakpoints.up("xs")]: {
      width: "93%",
      height: "100%",
      margin: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "40rem",
      margin: "2.5rem 1rem 1rem 1rem",
    },
  },
  marketingContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    padding: "0rem 0rem 1rem 0rem",
    display: "flex",
    justifyContent: "center",
    alignIterms: "center",
  },
  centeredTextContainer: {
    textAlign: "center",
    marginLeft: ".75rem",
    marginRight: ".75rem",
    marginBottom: "1rem",
  },
  leftJustifiedTextContainer: {
    textAlign: "left",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  headlineText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: 15,
    color: theme.palette.grey[300],
  },
  truckLottie: {
    width: "12rem",
    paddingBottom: "1rem",
    alignContent: "center",
  },
  blueText: {
    color: "#7BAEFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  underlinedText: {
    color: theme.palette.common.white,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "3.5rem",
      marginBottom: ".5rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "65%",
      height: "4rem",
      marginBottom: ".5rem",
    },
  },
  cardContent: {
    backgroundColor: "#132049",
    width: "100%",
    height: "100%",
    padding: ".5rem 0rem 0rem 0rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[900],
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonTextContainer: {
    width: "100%",
  },
  iconButton: {
    [theme.breakpoints.down("sm")]: {
      width: "2.5rem",
      height: "2.5rem",
      margin: "0rem .5rem 0rem .5rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "3rem",
      height: "3rem",
      margin: "0rem .5rem 0rem .5rem",
    },
  },
  innerTextContainer: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: ".5rem",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "1.25rem",
    },
  },
  iconContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: ".1rem",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: ".75rem",
    },
  },
  contactContainer: {
    width: "19rem",
    height: "1.4rem",
  },
  contactIcon: {
    width: "1.4rem",
  },
  linkText: {
    fontSize: ".9rem",
  },
}));

type CampaignParams = {
  campaignId: string;
};

const MarketingLandingPageComponent: React.FC<CampaignParams> = ({ campaignId }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const bgImage = textBackground;
  const bgImageDesktop = textBackgroundDesktop;

  const classes = useStyles({ bgImage, bgImageDesktop });

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "campaignLandingPageEvent",
        campaignId: campaignId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushInsuranceDataAndRedirect = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "campaignGetQuoteEvent",
        campaignGetQuoteText: "Get Quote",
      },
    });
    history.push({ pathname: routes.enrollment.qualify.path, state: campaignId });
  };

  const pushFactoringDataAndRedirect = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "campaignFactoringEvent",
        campaignFactoringText: "Factoring",
      },
    });
    window.location.assign("https://www.carrierhq.com/factoring-application");
  };

  const pushEquipmentDataAndRedirect = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "campaignEquipmentEvent",
        campaignEquipmentText: "Equipment",
      },
    });
    window.location.assign("https://www.carrierhq.com/equipment");
  };

  const truckOptions = {
    loop: true,
    autoplay: true,
    animationData: truckAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.partnerPaper} elevation={7}>
        <Grid container className={classes.marketingContainer} direction="column" justify="center">
          <Grid container item xs={12} className={classes.topContainer}>
            <Grid item className={classes.truckLottie}>
              <Lottie options={truckOptions} width={"100%"} />
            </Grid>
            <Grid item xs={12} className={classes.centeredTextContainer}>
              <Typography component="span" className={classes.headlineText}>
                {t("enrollment.marketing-landing-page.headline")}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.centeredTextContainer}>
              <Typography component="span" className={classes.subTitle}>
                {t("enrollment.marketing-landing-page.sub-title")}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.centeredTextContainer}
              direction="column"
              alignItems="center"
            >
              <Card className={classes.card}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => {
                    pushInsuranceDataAndRedirect();
                  }}
                >
                  <Grid
                    container
                    className={classes.buttonTextContainer}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid container item direction="row" alignItems="center" justify="flex-start" xs={12}>
                      <Grid item xs={2} className={classes.iconContainer}>
                        <img src={checkIcon} alt="insurance-button" className={classes.iconButton} />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={10}
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        className={classes.innerTextContainer}
                      >
                        <span>
                          <Trans i18nKey={"enrollment.marketing-landing-page.button-1-text"}>
                            <Typography variant="h4" component="span" className={classes.blueText}></Typography>
                            <Typography variant="h4" component="span" className={classes.underlinedText}></Typography>
                          </Trans>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => {
                    pushFactoringDataAndRedirect();
                  }}
                >
                  <Grid
                    container
                    className={classes.buttonTextContainer}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid container item direction="row" alignItems="center" justify="flex-start" xs={12}>
                      <Grid item xs={2} className={classes.iconContainer}>
                        <img src={moneyIcon} alt="factoring-button" className={classes.iconButton} />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={10}
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        className={classes.innerTextContainer}
                      >
                        <span>
                          <Trans i18nKey={"enrollment.marketing-landing-page.button-2-text"}>
                            <Typography variant="h4" component="span" className={classes.blueText}></Typography>
                            <Typography variant="h4" component="span" className={classes.underlinedText}></Typography>
                          </Trans>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => {
                    pushEquipmentDataAndRedirect();
                  }}
                >
                  <Grid
                    container
                    className={classes.buttonTextContainer}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid container item direction="row" alignItems="center" justify="flex-start" xs={12}>
                      <Grid item xs={2} className={classes.iconContainer}>
                        <img src={truckIcon} alt="equipment-button" className={classes.iconButton} />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={10}
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        className={classes.innerTextContainer}
                      >
                        <span>
                          <Trans i18nKey={"enrollment.marketing-landing-page.button-3-text"}>
                            <Typography variant="h4" component="span" className={classes.blueText}></Typography>
                            <Typography variant="h4" component="span" className={classes.underlinedText}></Typography>
                          </Trans>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} className={classes.centeredTextContainer}>
              <Typography component="span" className={classes.headlineText}>
                {t("enrollment.marketing-landing-page.footer")}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-start"
              className={classes.contactContainer}
            >
              <Grid container item xs={1} direction="row" alignItems="center" justify="flex-start">
                <Link variant="h4" href={"tel:866-971-5464"} className={classes.linkText}>
                  <img src={phoneIcon} alt="phone button" className={classes.contactIcon} />
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link variant="h4" href={"tel:866-971-5464"} className={classes.linkText}>
                  {t("enrollment.marketing-landing-page.phone")}
                </Link>
              </Grid>
              <Grid container item xs={1} direction="row" alignItems="center" justify="flex-start">
                <Link variant="h4" href={"mailto:info@CarrierHQ.com"} className={classes.linkText}>
                  <img src={emailIcon} alt="email button" className={classes.contactIcon} />
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link variant="h4" href={"mailto:info@CarrierHQ.com"} className={classes.linkText}>
                  {t("enrollment.marketing-landing-page.email")}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MarketingLandingPageComponent;
