/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Coverages
 */
export interface Coverages {
  /**
   *
   * @type {number}
   * @memberof Coverages
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof Coverages
   */
  trailerInterchangeCoverage?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Coverages
   */
  trailerInterchangeCoverageLimit?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Coverages
   */
  hiredAutoCovers?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Coverages
   */
  umUimCoverage?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Coverages
   */
  umUimCoverageStacking?: boolean | null;
}

export function CoveragesFromJSON(json: any): Coverages {
  return CoveragesFromJSONTyped(json, false);
}

export function CoveragesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coverages {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    trailerInterchangeCoverage: !exists(json, "trailerInterchangeCoverage")
      ? undefined
      : json["trailerInterchangeCoverage"],
    trailerInterchangeCoverageLimit: !exists(json, "trailerInterchangeCoverageLimit")
      ? undefined
      : json["trailerInterchangeCoverageLimit"],
    hiredAutoCovers: !exists(json, "hiredAutoCovers") ? undefined : json["hiredAutoCovers"],
    umUimCoverage: !exists(json, "umUimCoverage") ? undefined : json["umUimCoverage"],
    umUimCoverageStacking: !exists(json, "umUimCoverageStacking") ? undefined : json["umUimCoverageStacking"],
  };
}

export function CoveragesToJSON(value?: Coverages | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    trailerInterchangeCoverage: value.trailerInterchangeCoverage,
    trailerInterchangeCoverageLimit: value.trailerInterchangeCoverageLimit,
    hiredAutoCovers: value.hiredAutoCovers,
    umUimCoverage: value.umUimCoverage,
    umUimCoverageStacking: value.umUimCoverageStacking,
  };
}
