import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  drawer: {
    flexShrink: 0,
    "& .MuiPaper-root": {
      position: "absolute",
    },
  },
  icon: {
    justifyContent: "flex-end",
  },
  divider: {
    margin: "0.5rem 1rem",
  },
}));

export type HamburgerItem = {
  label: string | React.ReactFragment;
  externalRoute?: boolean;
  component?: React.ElementType;
  route?: string;
  onClick?: () => void;
  icon?: React.ReactFragment;
  dividerAbove?: boolean;
};

export type Props = {
  hamburgerItems: HamburgerItem[];
  activeClass?: string;
};

const HamburgerMenuItems: React.FC<Props> = ({ hamburgerItems, activeClass }) => {
  const classes = useStyles();

  return (
    <List>
      {hamburgerItems.map((item, index) => {
        return (
          <div key={`${item.label}-${index}`}>
            {item.dividerAbove && <Divider className={classes.divider} />}
            <ListItem
              button
              activeClassName={activeClass}
              exact
              component={
                item.externalRoute || item.component === undefined ? "a" : (item.component as React.ElementType)
              }
              onClick={() => {
                item.onClick && item.onClick();
              }}
              href={item.externalRoute ? item.route : undefined}
              rel={item.externalRoute ? "noopener noreferrer" : undefined}
              target={item.externalRoute ? "_blank" : undefined}
              to={item.externalRoute ? undefined : item.route}
            >
              <ListItemText primary={item.label} />
              {item.icon !== undefined && <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>}
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};

export default HamburgerMenuItems;
