import {
  BirthdatePicker,
  EditableDeletableCard,
  EditableDeletableCardProps,
  SaveButton,
  Select,
  TextInput,
} from "@chq/components";
import { State } from "@chq/enrollment-api";
import { Grid, IconButtonProps } from "@material-ui/core";
import { subYears } from "date-fns";
import startOfToday from "date-fns/startOfToday";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStates } from "../data/useStates";

export enum Fields {
  firstName = "first-name",
  lastName = "last-name",
  dateOfBirth = "date-of-birth",
  cdlNumber = "cdl-number",
  cdlState = "cdl-state",
}

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const today = startOfToday();
  const minBirthdate = subYears(today, 75);
  const maxBirthdate = subYears(today, 21);

  return yup.object({
    [Fields.firstName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.firstName}`) })),
    [Fields.lastName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.lastName}`) })),
    [Fields.dateOfBirth]: yup
      .date()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`) }))
      .typeError(t("errors.date", { field: t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`) }))
      .min(minBirthdate, t("enrollment.add-driver.errors.age-restriction"))
      .max(maxBirthdate, t("enrollment.add-driver.errors.age-restriction")),
    [Fields.cdlNumber]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.cdlNumber}`) })),
    [Fields.cdlState]: yup
      .string()
      .required(t(`errors.required`, { field: t(`enrollment.add-driver.fields.${Fields.cdlState}`) })),
  });
};

export type FormProps = {
  [Fields.firstName]: string;
  [Fields.lastName]: string;
  [Fields.dateOfBirth]?: Date;
  [Fields.cdlNumber]: string;
  [Fields.cdlState]?: State | "";
};

type Props = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  cdlNumber?: string;
  cdlState?: State | "";
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  onDelete?: EditableDeletableCardProps["onDelete"];
  driverNumber?: number;
};

export const AddDriverForm: React.FC<Props> = ({
  firstName: initialFirstName = "",
  lastName: initialLastName = "",
  dateOfBirth: initialDateOfBirth,
  cdlNumber: initialCdlNumber = "",
  cdlState: initialCdlState = "",
  onSubmit,
  onDelete,
  driverNumber = 1,
  IconButtonProps,
}) => {
  const [t] = useTranslation();
  const validationSchema = useValidationSchema();
  const states = useStates();

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.firstName]: initialFirstName,
      [Fields.lastName]: initialLastName,
      [Fields.dateOfBirth]: initialDateOfBirth,
      [Fields.cdlNumber]: initialCdlNumber,
      [Fields.cdlState]: initialCdlState,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
  });

  const checkIfErrorIsShowing = () => {
    const errors = Object.keys(formik.errors);
    const isErrorShowing = Object.keys(formik.touched).map((touched) => {
      return errors.includes(touched);
    });
    return isErrorShowing.includes(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCustomChange = async (date: any) => {
    await formik.setFieldValue(Fields.dateOfBirth, date); // The mui DatePicker does not always set a "touched" state, but manually
    formik.setFieldTouched(Fields.dateOfBirth); // setting it must happen AFTER the field value is set to make validation work correctly
  };

  return (
    <EditableDeletableCard
      title={t("enrollment.add-driver.title", { number: driverNumber })}
      error={checkIfErrorIsShowing()}
      variant="delete"
      onDelete={onDelete}
      IconButtonProps={{
        "aria-label": t("enrollment.add-driver.delete-button"),
        ...IconButtonProps,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  id={Fields.firstName}
                  name={Fields.firstName}
                  label={t(`enrollment.add-driver.fields.${Fields.firstName}`)}
                  value={formik.values[Fields.firstName]}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
                  helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  id={Fields.lastName}
                  name={Fields.lastName}
                  label={t(`enrollment.add-driver.fields.${Fields.lastName}`)}
                  value={formik.values[Fields.lastName]}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
                  helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BirthdatePicker
              id={Fields.dateOfBirth}
              name={Fields.dateOfBirth}
              inputProps={{ "aria-label": `${Fields.dateOfBirth}` }}
              label={t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`)}
              value={formik.values[Fields.dateOfBirth] || null}
              required
              fullWidth
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(date: any) => {
                setCustomChange(date);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.dateOfBirth] && Boolean(formik.errors[Fields.dateOfBirth])}
              helperText={formik.touched[Fields.dateOfBirth] && formik.errors[Fields.dateOfBirth]}
              KeyboardButtonProps={{
                "aria-label": t(`enrollment.add-driver.fields.${Fields.dateOfBirth}`),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              id={Fields.cdlNumber}
              name={Fields.cdlNumber}
              label={t(`enrollment.add-driver.fields.${Fields.cdlNumber}`)}
              value={formik.values[Fields.cdlNumber]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.cdlNumber] && Boolean(formik.errors[Fields.cdlNumber])}
              helperText={formik.touched[Fields.cdlNumber] && formik.errors[Fields.cdlNumber]}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              id={Fields.cdlState}
              name={Fields.cdlState}
              label={t(`enrollment.add-driver.fields.${Fields.cdlState}`)}
              items={states.map((state) => ({
                name: state.abv,
                value: state.abv,
              }))}
              value={formik.values[Fields.cdlState]}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.cdlState] && Boolean(formik.errors[Fields.cdlState])}
              helperText={formik.touched[Fields.cdlState] && formik.errors[Fields.cdlState]}
            />
          </Grid>
        </Grid>
        <SaveButton
          label={t("enrollment.add-driver.add-driver-button")}
          variant="outlined"
          fullWidth
          type="submit"
          disabled={!formik.isValid}
          formikValid={formik.isValid}
        />
      </form>
    </EditableDeletableCard>
  );
};

export default AddDriverForm;
